<template>
  <b-form @submit.prevent="saveCampaign">
    <b-row class="">
      <b-col cols="12">
        <b-form-group>
          <div class="demo-inline-spacing">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-images
              variant="outline-dark"
              block
              class="mr-0 p-1"
              @click="showAllImages"
            >
              Add media<feather-icon class="ml-1" icon="ImageIcon" size="20" />
            </b-button>
          </div>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="justify-content-md-center">
      <b-col cols="12">
        <div>
          <b-modal
            id="modal-images"
            title="Media Management"
            size="lg"
            scrollable
            ok-only
            ok-title="Close"
            ok-variant="outline-danger"
          >
            <b-form class="mt-2 mb-5">
              <b-row class="justify-content-md-center">
                <b-col cols="12">
                  <h1>Add Images</h1>
                </b-col>
                <b-col cols="8">
                  <b-form-group>
                    <b-form-file
                      id="image"
                      @change="ImageInputChanged"
                      accept="image/*"
                      placeholder="Choose an Image or drop it here..."
                      drop-placeholder="Drop here..."
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group>
                    <b-button
                      v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                      variant="outline-success"
                      class="mr-1"
                      @click="saveImage"
                    >
                      Save
                    </b-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
            <b-row>
              <b-col cols="12">
                <h1>All Images</h1>
                <hr />
              </b-col>
              <b-col cols="6" offset="6" class="mb-1">
                <b-form-group
                  label="Filter"
                  label-cols-sm="3"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="filterInput"
                  class="mb-0 d-flex align-items-center"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filterInput"
                      v-model="filter"
                      type="search"
                      placeholder="Type to Search"
                    />
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''">
                        Clear
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-table
                  striped
                  hover
                  responsive
                  :per-page="perPage"
                  :current-page="currentPage"
                  :items="imagesItems"
                  :fields="fields"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                >
                  <template #cell(id)="data">
                    <b-avatar size="70px" :src="data.item.url" />
                  </template>
                  <template #cell(url)="data">
                    <b-form-input
                      :readonly="true"
                      id="h-title"
                      v-model="data.item.url"
                      placeholder="Image URL"
                    />
                  </template>

                  <template #cell(action)="data">
                    <b-button-group>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="flat-dark"
                        class="btn-icon rounded-circle"
                        v-b-tooltip.hover
                        title="Copy Image URL"
                        v-clipboard:copy="data.item.url"
                        v-clipboard:success="__onCopy"
                        v-clipboard:error="__onError"
                        @click="copyText(data.item.url)"
                      >
                        <feather-icon icon="ClipboardIcon" size="20" />
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="flat-primary"
                        class="btn-icon rounded-circle"
                        v-b-tooltip.hover
                        title="Preview"
                        @click="previewImage(data.item.url)"
                      >
                        <feather-icon icon="EyeIcon" size="20" />
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="flat-danger"
                        class="btn-icon rounded-circle"
                        v-b-tooltip.hover
                        title="Delete"
                        @click="confirmDeleteImage(data.item.id)"
                      >
                        <feather-icon icon="Trash2Icon" size="20" />
                      </b-button>
                    </b-button-group>
                  </template>
                </b-table>
              </b-col>
              <b-col cols="6">
                <b-form-group class="mb-0">
                  <label class="d-inline-block text-sm-left mr-50"
                    >Per page</label
                  >
                  <b-form-select
                    id="perPageSelect"
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                    class="w-25"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="center"
                  size="sm"
                  class="my-0"
                />
              </b-col>
            </b-row>
          </b-modal>
        </div>
      </b-col>
    </b-row>
    <b-modal
      id="modal-danger"
      ok-variant="danger"
      ok-title="Confirm"
      modal-class="modal-danger"
      centered
      title="Deleting an Image ?!"
      @ok="deleteImage"
    >
      Are you sure you want to delete this Image?
    </b-modal>
  </b-form>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, url } from "@validations";
import vSelect from "vue-select";

import {
  BRow,
  BCol,
  BModal,
  VBModal,
  BCard,
  BAvatar,
  BFormSelect,
  BTable,
  BInputGroup,
  BFormInput,
  BFormGroup,
  BFormFile,
  BForm,
  BInputGroupAppend,
  BButtonGroup,
  VBTooltip,
  BPagination,
  BButton,
  BContainer,
} from "bootstrap-vue";

import axios from "@axios";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BContainer,
    BFormInput,
    BAvatar,
    BFormGroup,
    BFormFile,
    BFormSelect,
    BInputGroupAppend,
    BForm,
    BTable,
    BInputGroup,
    BModal,
    VBModal,
    BButtonGroup,
    VBTooltip,
    BPagination,
    BButton,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  // props: ["imageLink"],
  data() {
    return {
      path: this.$ImagesURL,
      _id: null,
      perPage: 10,
      pageOptions: [10, 25, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "id",
          label: "Avatar",
        },
        {
          key: "url",
          label: "Image URL",
        },
        {
          key: "action",
          label: "ACTION",
        },
      ],
      hello: "hey its me",
      imagesItems: [],
      //###########################
      title: null,
      isDisabled: true,
      url: null,
      image: null,
      test: "hellio",
      imageURL: null,
      data: {},
      // validation rules
      required,
      regex,
      url,
    };
  },
  computed: {},
  mounted() {},
  created() {},
  methods: {
    copyText(link) {
      navigator.clipboard.writeText(link);
    },
    __onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Image URL copied",
          icon: "ClipboardIcon",
          variant: "dark",
        },
      });
    },
    __onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Failed to copy text!",
          icon: "ClipboardIcon",
        },
      });
    },
    previewImage(url) {
      window.open(url, "_blank");
    },
    confirmDeleteImage(id) {
      this._id = id;
      this.$root.$emit("bv::show::modal", "modal-danger");
    },
    deleteImage() {
      axios
        .post("/delete-image", {
          id: this._id,
        })
        .then(({ data }) => {
          if (data.status === "success") {
            this.showToast("success", "TrashIcon", "Done", data.message);
            this.showAllImages();
          } else if (data.status === "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          } else if (data.status === "warning") {
            this.showToast(
              "warning",
              "AlertOctagonIcon",
              "Alert",
              data.message
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    ImageInputChanged(e) {
      this.image = e.target.files[0];
    },
    async saveImage() {
      let imageData = new FormData();

      imageData.append("image", this.image);

      await axios
        .post("/store-image", imageData)
        .then(({ data }) => {
          if (data.status == "success") {
            this.showToast("success", "SaveIcon", "Done", data.message);
            this.showAllImages();
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          }
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Something went wrong!"
          );
        });
    },
    async showAllImages() {
      await axios
        .get("/get-images")
        .then(({ data }) => {
          if (data.status == "success") {
            this.imagesItems = [];
            data.images.forEach((el) => {
              this.imagesItems.push({
                id: el.id,
                url: this.$ImagesURL + el.url,
              });
            });
            this.totalRows = this.imagesItems.length;
          } else if (data.status == "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              "Something went wrong!"
            );
          }
        })
        .catch((error) => {
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Something went wrong!"
          );
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
