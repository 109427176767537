<template>
  <b-card>
    <validation-observer ref="saveDomainForm" #default="{ invalid }">
      <b-form id="saveDomainForm" @submit.prevent="saveDomain">
        <b-container>
          <b-row class="justify-content-md-center">
            <b-col cols="12" md="2">
              <b-button
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                variant="flat-warning"
                class="btn-icon"
                to="/domains"
                v-b-tooltip.hover
                title="Go Back"
              >
                <feather-icon icon="ArrowLeftCircleIcon" size="20" />
              </b-button>
            </b-col>
            <b-col cols="12" md="10">
              <h1>Domain Creation</h1>
            </b-col>
          </b-row>
          <hr />
          <b-row class="justify-content-md-center">
            <b-col md="6" cols="12">
              <b-form-group label="Domain Name: *" label-for="d-name">
                <validation-provider
                  #default="{ errors }"
                  name="Domain Name"
                  rules="required|regex:^[a-zA-Z].*$"
                >
                  <b-form-input
                    id="d-name"
                    v-model="domain_name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Domain Name"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="justify-content-md-center">
            <b-col md="6" cols="12">
              <validation-provider name="Event URL" rules="url">
                <b-form-group label="Domain Link: *" label-for="event-url">
                  <validation-provider
                    #default="{ errors }"
                    name="Domain Link"
                    rules="required|url"
                  >
                    <b-form-input
                      id="event-url"
                      v-model="domain_link"
                      :state="errors.length > 0 ? false : null"
                      type="url"
                      placeholder="https://www.example.com"
                      trim
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <imgmng @onInsertImage="insertInsideTemplate" />
          <b-row class="justify-content-md-center">
            <b-col cols="12">
              <b-form-group label="Default Page:" label-for="did">
                <editor
                  api-key="puknvskl3ay0k40d6ltd1mq8fueses5pvh2xoktlmtssny8q"
                  v-model="templateContent"
                  :init="{
                    selector: '.editor',
                    plugins:
                      'fullpage code advlist link image preview lists wordcount',
                    bbcode_dialect: 'punbb',
                    height: 600,
                  }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="justify-content-md-center">
            <!-- submit and reset -->
            <b-col>
              <!-- <b-col offset-md="4"> -->
              <b-button
                type="submit"
                v-ripple.400="'rgba(255, 159, 67, 0.15)'"
                variant="outline-success"
                class="mr-1"
                :disabled="invalid"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                @click="previewTemplateOfCampaign"
              >
                Preview
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, regex, url } from "@validations";
import { removeLocalStorageItems } from "@/helper/helpers-funcs.js";

import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BFormGroup,
  BFormFile,
  BForm,
  VBTooltip,
  BButton,
  BContainer,
} from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import axios from "@axios";
import router from "@/router";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import Editor from "@tinymce/tinymce-vue";
import imagesManagement from "@/views/ads-manager/media-management/ImagesManagement.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BContainer,
    BFormInput,
    BFormGroup,
    BFormFile,
    imgmng: imagesManagement,
    BForm,
    VBTooltip,
    BButton,
    ValidationProvider,
    ValidationObserver,
    editor: Editor,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      domain_name: null,
      domain_link: null,
      templateContent: null,
      data: {},
      // validation rules
      required,
      regex,
      url,
    };
  },
  created() {},
  methods: {
    insertInsideTemplate(img) {
      const bodyTag = "</body>";
      let thereIsBody = this.templateContent.search(bodyTag);
      if (thereIsBody > 0) {
        let imgAdded = this.templateContent.replace("</body>", img + "</body>");
        this.templateContent = imgAdded;
        return;
      }
      this.templateContent += img;
    },
    previewTemplateOfCampaign() {
      localStorage.setItem(
        "templateContent",
        JSON.stringify(this.templateContent)
      );
      let routeData = router.resolve({
        name: "editor-preview-content",
      });
      window.open(routeData.href, "_blank");
    },
    showToast(variant, icon, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      });
    },
    async saveDomain() {
      let theSlash;
      const currentURL = window.location.href;
      const theSlashRegEx = "dev";
      let indexOfRegex = currentURL.search(theSlashRegEx);
      if (indexOfRegex < 0) {
        theSlash = "app";
      } else {
        theSlash = "dev";
      }
      const regExp = /[\/]$/;
      let results = regExp.test(this.domain_link);
      if (!results) this.domain_link += "/";
      await axios
        .post("/store-domain", {
          domain_link: this.domain_link,
          domain_name: this.domain_name,
          template: this.templateContent,
          theSlash: theSlash,
        })
        .then(({ data }) => {
          
          if (data.status === "Unauthorized") {
            removeLocalStorageItems();
            router.push({ name: "auth-login" });
          } else if (data.status === "success") {
            this.showToast("success", "SaveIcon", "Done", data.message);
            // this.$refs.resetButton.click();
            router.push({ name: "domains" });
          } else if (data.status === "failed") {
            this.showToast(
              "danger",
              "AlertTriangleIcon",
              "Error",
              data.message
            );
          }
        })
        .catch((error) => {
          
          this.showToast(
            "danger",
            "AlertTriangleIcon",
            "Error",
            "Something went wrong!"
          );
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
